import { buildURL, callAPI, Urls } from "helpers/apiUrls";
import { cacheManager, getUserInfo, getUUIDNumberFromLocalStorage } from "helpers/utilCommon";
import { setMiniAppLoader } from "redux/app/actions";
import { setMessage } from "redux/shared/actions";
import { setUserInfo } from '../redux/login/actions';

export const checkUser = () => {
    return dispatch => {
        const userInfo = getUserInfo();
        dispatch(setUserInfo(userInfo));
    }
}

export const flagFeature = (callback, isHideLoader = false) => {
    return (dispatch) => {
        if (!isHideLoader) {
            dispatch(setMiniAppLoader(true));
        }
        const url = buildURL(Urls.flagFeature, null, null, true);
        callAPI(url, 'get', null, true, (response) => {
            if (response && response.Status) {
                cacheManager.setItem('flagFeature', response.Result);
                callback(response.Result);
            } else {
                const uuidNumber = getUUIDNumberFromLocalStorage(url);
                dispatch(setMessage(true, '4003', uuidNumber));
                callback(null);
            }
            if (!isHideLoader) {
                dispatch(setMiniAppLoader(false));
            }
        });
    }
}

export const flagFeatureForDMS = (callback, dispatch, isHideLoader = false) => {
        if (!isHideLoader) {
            dispatch(setMiniAppLoader(true));
        }
        const url = buildURL(Urls.flagFeature, null, null, true);
        callAPI(url, 'get', null, true, (response) => {
            if (response && response.Status) {
                callback(response.Result);
            } else {
                const uuidNumber = getUUIDNumberFromLocalStorage(url);
                dispatch(setMessage(true, '4003', uuidNumber));
                callback(null);
            }
            if (!isHideLoader) {
                dispatch(setMiniAppLoader(false));
            }
        });
}

export const bannerFeatureAction = (callback) => {
    const url = buildURL(Urls.bannerFeature, null, null);
    callAPI(url, 'get', null, true, (response) => {
        if (response && response.status) {
            callback(response.content);
        } else {
            callback(null);
        }
    });
}

export const clearRedis = (cb) => {
    const url = `${buildURL(Urls.clearRedis, null, null)}`;
    callAPI(url, 'get', null, false, () => {
        cb();
    });
}

export default {
    checkUser,
    flagFeature,
    flagFeatureForDMS,
    bannerFeatureAction,
    clearRedis
}