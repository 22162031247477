const SET_APP_LOADER = 'SET_APP_LOADER';
const SET_MINI_APP_LOADER = 'SET_MINI_APP_LOADER';
const Alert = 'Alert';
const Mem_Step1 = 'Step1';
const Mem_Step2 = 'Step2';
const SET_TEMP_DATA = 'SET_TEMP_DATA';
const SET_TEST_MODE = 'SET_TEST_MODE';
const SET_FeatureFlag = 'SET_FeatureFlag'

export default {
    SET_APP_LOADER,
    SET_MINI_APP_LOADER,
    Alert,
    Mem_Step1,
    Mem_Step2,
    SET_TEMP_DATA,
    SET_TEST_MODE,
    SET_FeatureFlag
}