import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import moment from 'moment';
import { Accordion, Card, Spinner } from 'react-bootstrap';
import { find, isEmpty, map, uniq, sortBy } from 'lodash';
import { cookieKeysEnum, ILSCartId } from 'models/common.models';
import { ComponentUrls } from '../../models/navigation.models';
import { setCookie, checkCookie, removeCookie } from 'helpers/manageCookies';
import { encrypt, getFormatDate } from '../../helpers/utilCommon';
import {
    checkRosterPermission, getMyToolsMenuPermissionsAction, getNegativeAndCommentAction,
    getOutstandingBallotsListAction, getNextMeetingListAction, getAllWorkItemAction,
    getCollabWorkItemAction, getCollabListAction
} from '../../services/userServices';
import CustomTooltip from "../atoms/Tooltip";
import { IdentityDelegationLink } from './IdentityDelegationLink';

class MyToolMenu extends Component {

    state = {
        activeIndex: -1,
        workItemCommitteeList: [],
        outstandingBallotList: [],
        nextMeetingList: [],
        collaborationAreasInfo: { launchAdminCollaboration: false, hasActiveCommittee: false, collaboratinList: [], isTechnicalContact: false },
        negativeAndCommentInfo: {},
        showLoader: true,
        isCreateMyAgenda: false,
        inactivityReportInfo: [],
        memberCommittees: this.props.memberCommittees
    }

    accordionToggle = (eventKey, event) => {
        this.setState({ activeIndex: eventKey ? eventKey : -1 });
    }

    componentDidMount() {
        this.props.checkRosterPermission();
        if (this.props.loggedInMemberData && this.props.loggedInMemberData.MemberId > 0) {
            this.getMyToolsMenuPermissions(this.props.loggedInMemberData.MemberId);
        }
    }

    shouldComponentUpdate(nextProps) {
        // Get the data in case member id is fetched till componentdidmount
        if (this.props.loggedInMemberData.MemberId !== nextProps.loggedInMemberData.MemberId) {
            this.getMyToolsMenuPermissions(nextProps.loggedInMemberData.MemberId);
        }
        return true;
    }

    getMyToolsMenuPermissions = (memberId) => {
        let { userInfo } = this.props;
        let reqParams = { memberId, accountNumber: userInfo.accountNumber, MAEUserID: userInfo.MAEUserID }
        // This Code Is Commented Because Future Use
        this.props.getMyToolsMenuPermissions(reqParams, (response) => {
            this.setState({
                isCreateMyAgenda: response ? response.IsCreateMyAgenda : false,
                inactivityReportInfo: response && response.InactivityReportInfo ? response.InactivityReportInfo : []
            })
        });
        this.setState({
            workItemCommitteeList: [],
            outstandingBallotList: [],
            showLoader: false,
            nextMeetingList: [],
            collaborationAreasInfo: {},
            negativeAndCommentInfo: {},
        })
        this.props.getNegativeAndComment((response) => { this.setState({ negativeAndCommentInfo: response }) })
        this.props.getOutstandingBallotsList(reqParams, (response) => { this.setState({ outstandingBallotList: response }) })
        this.props.getNextMeetingList((response) => { this.setState({ nextMeetingList: response && response.length > 0 ? this.getFormattedData(response) : [] }) })
        this.props.getAllWorkItem(reqParams, (workItemResponse) => {
            this.props.getCollabWorkItem(reqParams, (collabResponse) => {
                let allWorkItemResponse = {};
                let committeeIds = [];
                allWorkItemResponse.committeeList = [];
                if (workItemResponse && workItemResponse.length > 0) {
                    committeeIds = workItemResponse.map(x => x.CommitteeId);
                }

                if (collabResponse && collabResponse.collabInfo && collabResponse.collabInfo.length > 0) {
                    allWorkItemResponse.collabInfo = collabResponse.collabInfo;
                    //let collabCommittee = collabResponse.committeeIds
                    if (committeeIds.length > 0) {
                        committeeIds = committeeIds.concat(collabResponse.committeeIds)
                    } else {
                        committeeIds = collabResponse.committeeIds;
                    }
                }
                if (committeeIds.length > 0) {
                    committeeIds = uniq(committeeIds);

                    let activeCommitteeResponse = [];
                    map(collabResponse.memberActiveCommittees, (item) => {
                        activeCommitteeResponse.push({ CommitteeId: item.CommitteeId, CommitteeDesignation: item.CommitteeDesignation });
                    })

                    if (activeCommitteeResponse.length > 0) {
                        let committeeList = activeCommitteeResponse.filter(x => committeeIds.indexOf(x.CommitteeId) !== -1);

                        allWorkItemResponse.committeeList = committeeList.length > 0 ? sortBy(committeeList, 'CommitteeDesignation') : [];
                        this.setState({ workItemCommitteeList: allWorkItemResponse.committeeList });
                    } else {
                        this.setState({ workItemCommitteeList: allWorkItemResponse.committeeList });
                    }
                } else {
                    this.setState({ workItemCommitteeList: allWorkItemResponse.committeeList });
                }
                this.props.getCollabList(reqParams, (collabListResponse) => {
                    collabListResponse.collaboratinList = collabResponse.collabInfo;
                    this.setState({ collaborationAreasInfo: collabListResponse });
                })
            })

        })
    }

    getClosingDate = (date) => {
        return date ? date.split(',')[0] : ''
    }

    getFormattedData = (list) => {
        list.map((item) => {
            item.dateInfo = getFormatDate(item.MeetingSymposiaStartDate) === getFormatDate(item.MeetingSymposiaEndDate) ? moment(item.MeetingSymposiaStartDate).format("MMM DD YYYY") : getFormatDate(item.MeetingSymposiaStartDate, 'MMM') === getFormatDate(item.MeetingSymposiaEndDate, 'MMM') ? moment(item.MeetingSymposiaStartDate).format("MMM DD") + ' - ' + moment(item.MeetingSymposiaEndDate).format("DD YYYY") : moment(item.MeetingSymposiaStartDate).format("MMM DD") + ' - ' + moment(item.MeetingSymposiaEndDate).format("MMM DD YYYY");
        })

        return list;
    }

    closeNavDropDown = () => {
        const { handleMyToolMenu } = this.props
        if (handleMyToolMenu) {
            this.setState({ activeIndex: -1 })
            handleMyToolMenu(false);
        }
    }

    validateMyASTMCookies = (e, url) => {
        const { authState, userInfo } = this.props;
        if (authState && authState.isAuthenticated) {
            setCookie(cookieKeysEnum["_MyASTM"], userInfo.memberAccountNumber, process.env.REACT_APP_COOKIES_EXPIRATION_TIME);
            setTimeout(() => {
                window.open(url, "_blank")
            }, 100);
        }
    }

    validateIsILSCookies = (e, url) => {
        const { authState, userInfo } = this.props;
        if (authState && authState.isAuthenticated) {
            if(checkCookie(cookieKeysEnum["ILSCookie"])){
                removeCookie(cookieKeysEnum["ILSCookie"]);
            }
            setCookie(cookieKeysEnum["ILSCookie"], encrypt(`${userInfo.email};${userInfo.memberAccountNumber}`), process.env.REACT_APP_COOKIES_EXPIRATION_TIME);
            setTimeout(() => {
                window.open(url, "_blank")
            }, 100);
        }
    }

    render() {
        const { workItemCommitteeList, outstandingBallotList, showLoader, collaborationAreasInfo, negativeAndCommentInfo, isCreateMyAgenda, inactivityReportInfo, memberCommittees, nextMeetingList } = this.state
        const { userInfo, userPermission, isPermissionLoader, flagFeature } = this.props;
        let rosterPermission = null;
        let isActivityReport = false;
        let myAgendaUrl = `${process.env.REACT_APP_CREATE_AGENDA_URL}?account=${userInfo.memberAccountNumber}`
        let isNewTab = true;
        let isNewMeetingMaterialTab = true;
        let isNewMyScheduleTab = true;
        if (flagFeature && flagFeature?.MyAgenda) {
            myAgendaUrl = `${process.env.REACT_APP_GLOBAL_MEETING_SYMPOSIA_URL}/MyAgenda`
            isNewTab = false;
        }
        let meetingMaterialUrl = `${process.env.REACT_APP_CREATE_MEETING_MATERIALS_URL}?account=${userInfo.memberAccountNumber}`
        if (flagFeature && flagFeature?.MeetingMaterial) {
            meetingMaterialUrl = `${process.env.REACT_APP_GLOBAL_MEETING_SYMPOSIA_URL}/MyMeetingMaterial`
            isNewMeetingMaterialTab = false;
        }
        let myScheduleUrl = `${process.env.REACT_APP_CREATE_SCHEDULE_URL}?account=${userInfo.memberAccountNumber}`
        if (flagFeature && flagFeature?.MySchedule) {
            myScheduleUrl = `${process.env.REACT_APP_GLOBAL_MEETING_SYMPOSIA_URL}/MySchedule`
            isNewMyScheduleTab = false;
        }
        if (!isEmpty(userPermission) && !isEmpty(userPermission.Permissions)) {
            rosterPermission = 'report';
            const permissions = userPermission.Permissions;
            const checkEditorPermission = find(permissions, (data) => {
                return data.HasPermission && (data.ResponsibilityPrivilegeId === 1 || data.ResponsibilityPrivilegeId === 2 || data.ResponsibilityPrivilegeId === 3)
            });
            if (!isEmpty(checkEditorPermission)) {
                rosterPermission = 'maintenance';
            }
            find(permissions, (data) => {
                if (!isActivityReport)
                    isActivityReport = (data.HasPermission && data.ResponsibilityPrivilegeId === 6);
            });
        }

        return (
            <div data-testid="myTools" className="myTools">
                <h4 className="astm-type-heading--h4">MyTools</h4>
                <Accordion className="astm-accordion astm-accordion--full-width" onSelect={this.accordionToggle}>
                    {isPermissionLoader ? (
                        <Spinner animation="border" size='sm' />
                    ) : rosterPermission ? (
                        <React.Fragment>
                            <Card className="astm-card">
                                <Accordion.Toggle as={Card.Header} eventKey="8">
                                    <h2 className="mb-0">
                                        <CustomTooltip title="Roster report/maintenance" placement='top'
                                            HTMLElement={<a href={process.env.REACT_APP_ROSTER_URL} className="btn astm-btn btn-link astm-link lineHeightFix" title="">
                                                {rosterPermission === 'report' ? "Roster Reports" : (
                                                    <>Roster Maintenance {userPermission.TotalPendingApplications > 0 && (<span className="badge astm-badge bg-danger">{userPermission.TotalPendingApplications}</span>)}</>
                                                )}
                                            </a>}
                                        />
                                    </h2>
                                </Accordion.Toggle>
                            </Card>
                        </React.Fragment>
                    ) : null}

                    {negativeAndCommentInfo && negativeAndCommentInfo.HasPermission && <React.Fragment>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="8" >
                                <h2 className="mb-0">
                                    <CustomTooltip title="Indicates the number of negative votes requiring resolution" placement='top'
                                        HTMLElement={<button
                                            className="btn astm-btn btn-link astm-link lineHeightFix" title="" onClick={(e) => this.validateMyASTMCookies(e, process.env.REACT_APP_NEGATIVE_COMMENTS.replace("loggedInMemberAccountNo", userInfo.memberAccountNumber).replace("loggedInMemberAccountType", negativeAndCommentInfo.AccountType ? negativeAndCommentInfo.AccountType : ''))}>
                                            Negative &amp; Comments <span className="badge astm-badge bg-danger"> {negativeAndCommentInfo.CommentsCount}</span>
                                        </button>}
                                    />
                                </h2>
                            </Accordion.Toggle>
                        </Card>
                    </React.Fragment>
                    }

                    {showLoader ? <Spinner animation="border" size='sm' />
                        : outstandingBallotList.length > 0 && <React.Fragment>
                            <Card data-testid="outstandingBallotsList" className="astm-card">
                                <Accordion.Toggle as={Card.Header} eventKey="0" >
                                    <h2 className="mb-0">
                                        <CustomTooltip title="Indicates number of ballots that you need to return" placement='top'
                                            HTMLElement={<button className="btn astm-btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                MyOutstanding Ballots <span className="badge astm-badge bg-danger">{outstandingBallotList.length}</span>
                                            </button>}
                                        />
                                    </h2>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <ul>
                                            {outstandingBallotList.map((ballotItem, index) => {
                                                return <li className="active" key={index}>
                                                    <a href={`${process.env.REACT_APP_BA_URL}myballots/${ballotItem.LetterBallot}`} className="astm-link">
                                                        {ballotItem.FormattedLetterBallot} closes {this.getClosingDate(ballotItem.ClosingDate)}
                                                    </a>
                                                </li>
                                            })}
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </React.Fragment>
                    }

                    {showLoader ? <Spinner animation="border" size='sm' />
                        : nextMeetingList.length > 0 && <Card className="astm-card">
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                                <h2 className="mb-0">
                                    <button className="btn astm-btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#collapseOne" aria-expanded="true"
                                        aria-controls="collapseOne">MyNext Meetings <span className="badge astm-badge bg-danger">{nextMeetingList.length}</span></button>
                                </h2>

                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <ul>
                                        {nextMeetingList.map((meeting, index) => {
                                            return (
                                                <li key={index}>
                                                    <IdentityDelegationLink href={`${process.env.REACT_APP_EVENTS_URL}${meeting.WebsiteId}/${meeting.WebsiteLink}`}
                                                        target="_blank" className="btn astm-btn btn-link astm-link lineHeightFix" title="">
                                                        {meeting.CommitteeDesignation} {meeting.dateInfo}
                                                    </IdentityDelegationLink>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    }

                    {showLoader ? <Spinner animation="border" size='sm' />
                        : workItemCommitteeList.length > 0 && <React.Fragment>
                            <Card className="astm-card">
                                <Accordion.Toggle as={Card.Header} eventKey="2">
                                    <h2 className="mb-0">
                                        <button className="btn astm-btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">MyWork Items</button>
                                    </h2>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        <ul>
                                            <li><Link to={ComponentUrls.MyWorkItems} className="astm-link" onClick={this.closeNavDropDown}>All</Link></li>
                                            {workItemCommitteeList.map((committee, index) => {
                                                return (
                                                    <li key={index}>
                                                        <Link to={`${ComponentUrls.MyWorkItems}/${encrypt(committee.CommitteeId.toString())}/${committee.CommitteeDesignation}`} className="astm-link" onClick={this.closeNavDropDown}>
                                                            {committee.CommitteeDesignation}
                                                        </Link>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>

                            </Card>
                        </React.Fragment>
                    }

                    {showLoader ? <Spinner animation="border" size='sm' />
                        : (process.env.REACT_APP_IS_SPB_ENABLED === 'true' && (collaborationAreasInfo.launchAdminCollaboration || collaborationAreasInfo.isTechnicalContact ||
                            (collaborationAreasInfo.hasActiveCommittee && collaborationAreasInfo.collaboratinList.length > 0))) && <React.Fragment>
                            <Card className="astm-card">
                                <Accordion.Toggle as={Card.Header} eventKey="3">
                                    <h2 className="mb-0">
                                        <button className="btn astm-btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">MyCollaboration Areas</button>
                                    </h2>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        <ul>
                                            {
                                                collaborationAreasInfo.launchAdminCollaboration &&
                                                <li><a target="_blank" className="astm-link" href={`${process.env.REACT_APP_COLLABORATION_AREA_LAUNCH_URL}?accountID=${process.env.REACT_APP_SPECBUILDER_ACCOUNT_ID}`}>Launch Admin Collaboration Area</a></li>
                                            }
                                            <li><a href={`${process.env.REACT_APP_WI_URL}collaboration-item/detail`} className={(collaborationAreasInfo.launchAdminCollaboration || collaborationAreasInfo.isTechnicalContact) ? "astm-link" : "astm-link disabled"}>Create Work Item Collaboration</a></li>
                                            {(collaborationAreasInfo.hasActiveCommittee && collaborationAreasInfo.collaboratinList.length > 0) && collaborationAreasInfo.collaboratinList.map((colabItem, index) => {
                                                return (
                                                    <li key={index}>
                                                        <a className="astm-link twoLiner" target="_blank" href={`${process.env.REACT_APP_SPECBUILDER_HOST}/user/group/overview/${colabItem.collaborationAreaID}?accountID=${process.env.REACT_APP_SPECBUILDER_ACCOUNT_ID}`} >{colabItem.workItemTitle}</a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </React.Fragment>
                    }

                    <Card className="astm-card">
                        <Accordion.Toggle as={Card.Header} eventKey="5">
                            <h2 className="mb-0">
                                <button className="btn astm-btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Ballots &amp; Work Items</button>
                            </h2>
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey="5">
                            <Card.Body>
                                <ul>
                                    <li><a href={process.env.REACT_APP_WI_URL + ComponentUrls.WorkItemApp} className="astm-link">Submit/Edit</a></li>
                                    {((isActivityReport && isActivityReport === true) || (inactivityReportInfo && inactivityReportInfo.length > 0 && inactivityReportInfo[0].BallotInactivityCount > 0)) &&
                                        < li > <a href="javascript:void(0);" className="astm-link" onClick={(e) => this.validateMyASTMCookies(e, process.env.REACT_APP_BALLOT_INACTIVITY_REPORT_URL.replace("loggedInMemberAccountNo", userInfo.memberAccountNumber))}>Inactivity Reports</a></li>
                                    }
                                    {
                                        collaborationAreasInfo.launchAdminCollaboration &&
                                        <li><a target="_blank" href={`${process.env.REACT_APP_COLLABORATION_AREA_LAUNCH_URL}?accountID=${process.env.REACT_APP_SPECBUILDER_ACCOUNT_ID}`} className="astm-link">Launch Admin Collaboration Area</a></li>
                                    }
                                </ul>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    {memberCommittees && memberCommittees.length > 0 &&
                        <Card className="astm-card shadow-none">
                            <Accordion.Toggle as={Card.Header} eventKey="6">
                                <h2 className="mb-0">
                                    <button className="btn astm-btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Meetings, Minutes & Agendas</button>
                                </h2>
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="6">
                                <Card.Body>
                                    <ul>
                                        {isCreateMyAgenda &&
                                            <li className="active"><a target={isNewTab ? "_blank" : ""} href={myAgendaUrl} className="astm-link">Create MyAgenda</a></li>
                                        }
                                        <li className="active"><a target={isNewMyScheduleTab ? "_blank" : ""} href={myScheduleUrl} className="astm-link">Create MySchedule</a></li>
                                        <li className="active"><a target={isNewMeetingMaterialTab ? "_blank" : ""} href={meetingMaterialUrl} className="astm-link">Create MyMeeting Materials</a></li>
                                        {isCreateMyAgenda && <li className="active"><Link data-testid="SubmitMinutesAgendaLink" to={ComponentUrls.SubmitMinutesAgenda} className="astm-link" onClick={this.closeNavDropDown}>Submit Minutes &amp; Agendas</Link></li>}
                                        <li className="active"><Link to={ComponentUrls.MinutesAgendaClosingReportTracker} className="astm-link" onClick={this.closeNavDropDown}>Agendas/Minutes/Closing Reports tracker</Link></li>
                                    </ul>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    }
                    <Card className="astm-card shadow-none" as={Card.Header} >
                        <h2 className="mb-0">
                            <button className="btn astm-btn btn-link" title="" onClick={(e) => this.validateIsILSCookies(e, `${process.env.REACT_APP_ILS_URL}?MemberId=${userInfo.memberAccountNumber}&cartid=${ILSCartId}`)}>
                                Interlaboratory Study (ILS)
                            </button>
                        </h2>
                    </Card>

                    <Card className="astm-card shadow-none" as={Card.Header}>
                        <h2 className="mb-0">
                            {(flagFeature && flagFeature?.TerminologyDictionary) &&
                                <a href={process.env.REACT_APP_STD_URL} className="btn astm-btn btn-link vertical-Center" >Terminology Dictionary</a>}
                        </h2>
                    </Card>

                    <Card className="astm-card shadow-none" as={Card.Header}>
                        <h2 className="mb-0">
                            <Link to={ComponentUrls.VirtualMeetings} className="btn astm-btn btn-link vertical-Center" onClick={this.closeNavDropDown}>Plan Online Mtg/Conf</Link>
                        </h2>
                    </Card>

                    <Card className="astm-card shadow-none" as={Card.Header}>
                        <h2 className="mb-0">
                            <a target="_blank" href={process.env.REACT_APP_PUB_MEMBER_TRAINING_URL} className="btn astm-btn btn-link vertical-Center">Member Training</a>
                        </h2>
                    </Card>

                </Accordion>
            </div >

        )
    }
}

const mapStateToProps = (state) => {
    const { user, entitlement, app } = state;
    return {
        userInfo: user.userInfo,
        userPermission: user.userPermission,
        isPermissionLoader: user.isPermissionLoader,
        loggedInMemberData: user.loggedInMemberData,
        memberCommittees: entitlement?.entitlementList?.otherInfo?.activeMainCommittee,
        flagFeature: app.featureFlag
    }
}

const mapDispatchToProps = dispatch => ({
    checkRosterPermission: () => dispatch(checkRosterPermission()),
    getMyToolsMenuPermissions: (memberId, callback) => getMyToolsMenuPermissionsAction(memberId, callback, dispatch),
    getNegativeAndComment: (callback) => getNegativeAndCommentAction(callback, dispatch),
    getOutstandingBallotsList: (memberId, callback) => getOutstandingBallotsListAction(memberId, callback, dispatch),
    getNextMeetingList: (callback) => getNextMeetingListAction(callback, dispatch),
    getAllWorkItem: (memberId, callback) => getAllWorkItemAction(memberId, callback, dispatch),
    getCollabWorkItem: (MAEUserID, callback) => getCollabWorkItemAction(MAEUserID, callback, dispatch),
    getCollabList: (memberId, callback) => getCollabListAction(memberId, callback, dispatch)
});

const MyToolMenuComponent = (props) => {
    return <MyToolMenu {...props} ></MyToolMenu>
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withOktaAuth(MyToolMenuComponent));
