import types from './types';

export const setAppLoader = (value) => ({
    type: types.SET_APP_LOADER,
    value
});

export const setMiniAppLoader = (value) => ({
    type: types.SET_MINI_APP_LOADER,
    value
});

export const setTempData = (data) => ({
    type: types.SET_TEMP_DATA,
    value: data
});

export const setTestMode = (data) => ({
    type: types.SET_TEST_MODE,
    value: data
});

export const setFeatureFlagData = (data) =>({
    type: types.SET_FeatureFlag,
    value: data
});

export default {
    setAppLoader,
    setMiniAppLoader,
    setTempData,
    setTestMode,
    setFeatureFlagData
}